import { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { hasToken } from '../services/httpService';
import useDeleteParams from './useDeleteParams';

export default function useMagicLogin(): boolean {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const link = params.get('link');
  const isLoggedIn = hasToken();

  useEffect(() => {
    if (link && !isLoggedIn) {
      navigate(`/magic?link=${link}&redirectTo=${encodeURIComponent(`${location.pathname}${location.search}`)}`);
    }
  }, []);

  if (isLoggedIn && link) {
    useDeleteParams('link');
  }
  return !!link && !isLoggedIn;
}
